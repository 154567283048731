import mango from 'mango-js';

export default function initAnims() {

  mango();
	
	const text = document.querySelectorAll('.text-anim');
	const img = document.querySelectorAll('.img-anim');
  const footer = document.querySelector('.case__footer');

  const mainImg = document.querySelector('.case .img');
  const mainImgTop = mainImg.getBoundingClientRect().y - window.innerHeight;
  const mainImgMax = mainImg.getBoundingClientRect().y - window.innerHeight + mainImg.offsetHeight;
  const mainTitle = document.querySelector('.case .head');

  const menu = document.querySelector('.menu');
  const menuBlack = document.querySelectorAll('.menu-black');

  let isBlack = false;

	lenis.on('scroll', (e) => {

    if(lenis.animatedScroll > 0 && lenis.animatedScroll < window.innerHeight) {
      const progress = lenis.animatedScroll / window.innerHeight;
      
      mainImg.style.width = window.innerWidth - (progress * 30) + 'px';
    }
    if(lenis.animatedScroll <= 0) {
      mainImg.style.width = '';
    }

    text.forEach(el => {
      if(isInViewport(el) && !el.classList.contains('anim')) {
        el.classList.add('anim');
      }
    });
    img.forEach(el => {
      if(isTopInViewport(el) && !el.classList.contains('anim')) {
        el.classList.add('anim');
      }
    });
    if(isInViewport(footer) && !footer.classList.contains('anim')) {
      footer.classList.add('anim');
    }

    if(menuBlack) {
      isBlack = false;
      menuBlack.forEach(el => {
        if(lenis.animatedScroll >= (el.offsetTop - window.innerHeight) && lenis.animatedScroll <= (el.offsetTop + el.offsetHeight - window.innerHeight)) {
          isBlack = true;
        }
      });
    }

    if(isBlack) {
      menu.classList.add('black'); 
    } else {
      menu.classList.remove('black');
    }
  })
}